import { css } from '@emotion/core';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  typescale,
  unit,
} from 'styles';

import { buttons } from './button.styles';
import { mqFrom, mqTo } from './responsive.styles';

const root = css`
  padding: ${unit * 3}px ${unit * 3}px;
  transition: all 0.5s;

  ${mqFrom.Sml} {
    padding: ${unit * 8}px ${unit * 8}px;
  }

  ${mqFrom.Med} {
    padding: ${unit * 15}px ${unit * 15}px;
  }

  ${mqFrom.Lrg} {
    padding: ${unit * 20}px ${unit * 30}px;
  }
`;

const pageTitle = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  ${typescale.medium};

  color: ${colors.dark};

  max-width: 35%;

  ${mqFrom.Med} {
    ${typescale.large};
  }

  ${mqFrom.Lrg} {
    ${typescale.xlarge};
  }
`;

const section = css`
  display: flex;
  padding-bottom: ${unit * 2}px;
  flex-direction: column;
  align-items: flex-start;

  ${mqFrom.XSml} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-bottom: ${unit * 4}px;
  }
`;

const infoWrapper = css`
  max-width: 100%;
  margin-top: ${unit * 3}px;
  background-color: rgb(232, 237, 250);
  display: flex;
  align-items: center;
  justify-content: center;

  ${mqFrom.XSml} {
    max-width: 60%;
    margin-top: 0;
  }
`;

const info = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  ${typescale.small};

  color: ${colors.info};
  padding: ${unit * 2}px ${unit * 5}px;
`;

const gridWrapper = css`
  display: flex;
  flex-direction: column;

  ${mqFrom.XSml} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 2fr;
    grid-gap: ${unit}px ${unit}px;
  }

  ${mqFrom.Sml} {
    grid-gap: ${unit * 2}px ${unit * 3}px;
  }

  ${mqFrom.Med} {
    grid-gap: ${unit * 4}px ${unit * 5}px;
  }

  padding: ${unit * 3}px 0;
  align-items: center;
`;

const contentTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;
  font-size: 12px;
  line-height: 12px;

  color: ${colors.dark};
  opacity: 0.4;

  ${mqTo.XSml} {
    display: none;
  }

  text-transform: uppercase;
`;

const line = css`
  width: 100%;
  height: 1px;
  background-color: ${colors.dark};
  opacity: 0.1;
  margin-top: ${unit * 2}px;
  grid-column-start: span 5;
`;

const fullLine = css`
  width: 100%;
  height: 1px;
  background-color: ${colors.dark};
  opacity: 0.1;
  margin-top: ${unit * 2}px;
`;

const priceWrapper = css`
  display: flex;
  flex-direction: row;

  padding: ${unit * 2}px 0;

  ${mqFrom.XSml} {
    padding: 0;
  }
`;

const icon = css`
  display: flex;
  border-radius: 50%;
  margin: 0 ${unit * 2}px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease-out;
  border: none;
  background-color: transparent;
  outline: none;

  &:hover {
    background-color: #e0e0e0;
    &:disabled  {
      background-color: rgba(179, 0, 0, 0.4);
      cursor: not-allowed;
    }
  }
`;

const totalWrapper = css`
  width: 100% ${mqFrom.XSml} {
    width: 65%;
    float: right;
  }

  ${mqFrom.Sml} {
    width: 50%;
    float: right;
  }
`;

const flex = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const imageWrapper = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  padding: ${unit * 2}px 0;

  ${mqFrom.XSml} {
    padding: 0;
  }
`;

const productTitle = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.small}px;
  line-height: ${lineHeight.small}px;

  color: ${colors.primary};

  &:hover  {
    text-decoration: underline;
  }
`;

const text = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.base}px;
  line-height: ${lineHeight.base}px;
  margin: 0 ${unit * 2}px;
  color: ${colors.dark};
  align-self: center;
`;

const descriptionText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  color: ${colors.dark};
  opacity: 0.6;

  padding-top: ${unit}px;
`;

const taxesText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: ${fontSize.base}px;
  line-height: ${lineHeight.base}px;

  color: ${colors.dark};
  padding: ${unit}px 0;
  opacity: 0.6;
`;

const noOpacity = css`
  opacity: 1;
`;

const boldText = css`
  font-weight: ${fontWeights.weightBold};
`;

const justifySelfEnd = css`
  justify-self: flex-end;
`;

const taxesPadding = css`
  padding-top: ${unit * 4}px;
  padding-bottom: ${unit * 10}px;
`;

const redLine = css`
  width: 6px;
  margin-right: 10px;
  background-color: ${colors.primary};
`;

const infoPadding = css`
  padding: ${unit * 10}px 0;
`;

const offerText = css`
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-style: normal;

  font-size: ${fontSize.tiny}px;
  line-height: ${lineHeight.tiny}px;

  color: ${colors.textSecondary};
  opacity: 0.6;
`;

const removeWrapper = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  ${mqFrom.XSml} {
    justify-content: space-between;
  }
`;

const close = css`
  svg {
    transition: all 0.5s ease-out;
    cursor: pointer;

    &:hover {
      g  {
        opacity: 1;
      }
      #hover {
        fill: ${colors.primary};
        stroke: ${colors.bg};
      }
    }
  }
`;

const quantityResponsive = css`
  ${mqTo.XSml} {
    display: none;
  }
`;

const offerButton = css`
  ${mqTo.XSml} {
    width: 100%;
    justify-content: center;
  }
`;

const disabledButton = css`
  ${buttons.disabled};
  ${buttons.hidden};
`;

const buttonDelete = css`
  ${buttons.teritary.medium}

  display: inline-block;
  width: 100%;

  ${mqFrom.Lrg} {
    margin-left: ${unit}px;
    margin-top: 0;
  }

  margin-top: ${unit * 2}px;
  ${offerButton};
`;

const buttonAdd = css`
  ${buttons.primary.medium};

  width: 100%;

  ${mqFrom.Lrg} {
    margin-right: ${unit}px;
  }

  display: inline-block;

  ${offerButton};
`;

const buttonWrapper = css`
  ${mqFrom.Lrg} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  flex-direction: column;
`;

const copy = css`
  margin-bottom: 60px;
`;

export const cartHome = {
  root,
  pageTitle,
  section,
  infoWrapper,
  info,
  gridWrapper,
  contentTitle,
  line,
  priceWrapper,
  icon,
  totalWrapper,
  flex,
  fullLine,
  productTitle,
  imageWrapper,
  text,
  descriptionText,
  taxesText,
  noOpacity,
  boldText,
  justifySelfEnd,
  taxesPadding,
  redLine,
  infoPadding,
  offerText,
  removeWrapper,
  close,
  copy,
  quantityResponsive,
  offerButton,
  disabledButton,
  buttonAdd,
  buttonDelete,
  buttonWrapper,
};
